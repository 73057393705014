import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
})

class DeleteConfirmDialog extends Component {
  setConfirmed() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    const {onDelete} = this.props;
    if (!onDelete) {
      return;
    }
    onDelete();
  }

  render() {
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle><FormattedMessage id='deleteConfirmDialog' /></DialogTitle>
        <Container>
          <FormattedMessage id='deleteConfirmMessage' />
        </Container>
        <DialogActions>
          <Button
            onClick={this.props.onClose}
          >
            <FormattedMessage id='cancel' />
          </Button>
          <Button
            onClick={() => this.setConfirmed()}
            color='secondary'
          >
            <FormattedMessage id='deleteConfirm' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(DeleteConfirmDialog)
