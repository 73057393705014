// web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDPjIT0V2CBy497ZFZfYtSlofa-GrzUw7E",
  authDomain: "dynname.firebaseapp.com",
  projectId: "dynname",
  storageBucket: "dynname.appspot.com",
  messagingSenderId: "724881686197",
  appId: "1:724881686197:web:aec8ce64022717c09b88fc",
  measurementId: "G-THL01RXKNB"
};
