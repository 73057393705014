import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { FormattedMessage, injectIntl } from 'react-intl'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import axios from 'axios'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AddIcon from '@material-ui/icons/Add';
import ComputerIcon from '@material-ui/icons/Computer';
import DnsIcon from '@material-ui/icons/Dns';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Button from '@material-ui/core/Button'
import UserSettingsPage from './UserSettingsPage';
import Proxy from '../components/Proxy.js';
import Name from '../components/Name.js';
import NewNameDialog from '../components/NewNameDialog';
import ReservedNamesExceededDialog from '../components/ReservedNamesExceededDialog';
import Tutorial from '../components/Tutorial';
import LoadingPanel from '../components/LoadingPanel.js'

const DEFAULT_VISIBLE_SOURCES = 3;
const HASH_TABS = ['#proxies', '#names'];
const NUM_NAME_CANDIDATES = 3;
const DEFAULT_QUOTA = {
  reservedNames: 3,
  enabledNames: 1,
};

const styles = (theme) => ({
  title: {
    fontSize: '2em',
  },
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  addButton: {
    margin: '0.5em',
  },
  proxyTutorial: {
    textAlign: 'right',
    marginTop: '0.5em',
  }
})

function checkName(name) {
  return name.match(/^[a-z][a-z0-9-]{3,31}$/) !== null;
}

class UserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newName: false,
    };
  }

  showError(message, error) {
    console.error(message)
    console.error(error)
    if (!this.props.onError) {
      return
    }
    this.props.onError(message, error)
  }

  componentDidMount() {
    this.load()
      .then((data) => {
        this.setState(data, () => {
          if (this.getNameID()) {
            this.selectTab(null, HASH_TABS.findIndex((tab) => tab === '#names'));
          }
        });
      })
      .catch((error) => {
        this.showError('errorUserLoad', error);
      })
  }

  getActivatingProxyID() {
    const m = window.location.pathname.match(/^\/p\/([0-9a-zA-Z_-]+)$/);
    if (!m) {
      return null;
    }
    return m[1];
  }

  getNameID() {
    const m = window.location.pathname.match(/^\/n\/([0-9a-zA-Z_-]+)$/);
    if (!m) {
      return null;
    }
    return m[1];
  }

  async load() {
    const proxyID = this.getActivatingProxyID();
    let activated = {};
    if (proxyID) {
      activated = await this.activateProxy(proxyID);
    }
    const firestore = this.props.firestore
    const proxyRecords = await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('proxy')
      .orderBy('updated', 'desc')
      .get();
    const proxies = proxyRecords.docs.map((proxy) => ({
      id: proxy.id,
      data: proxy.data(),
    }));
    const nameRecords = await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('name')
      .orderBy('updated', 'desc')
      .get();
    const names = nameRecords.docs.map((name) => ({
      id: name.id,
      data: name.data(),
    }));
    const name = this.getNameID() ? names.filter((name) => name.id === this.getNameID())[0] : null;
    const quotaRef = await firestore
      .collection('quota')
      .doc(this.props.user.uid)
      .get();
    const quota = {
      data: quotaRef.exists ? quotaRef.data() : DEFAULT_QUOTA,
    };
    return Object.assign({}, activated, {
      proxies,
      names,
      name,
      quota,
    });
  }

  async activateProxy(proxyID) {
    const firestore = this.props.firestore
    const proxyDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('proxy')
      .doc(proxyID)
    const proxyRef = await proxyDocRef.get();
    if (proxyRef.exists && proxyRef.data().activated) {
      return {
        proxy: {
          id: proxyRef.id,
          data: proxyRef.data(),
        },
      }
    }
    await proxyDocRef.set({});
    const url = `/api/v1/users/${this.props.user.uid}/proxies/${proxyID}`;
    await axios.post(url);
    const uProxyRef = await proxyDocRef.get();
    if (!uProxyRef.exists || !uProxyRef.data().activated) {
      throw new Error('Unexpected error');
    }
    return {
      proxy: {
        id: uProxyRef.id,
        data: uProxyRef.data(),
      },
    };
  }

  async performAddName(newName, proxyID) {
    const firestore = this.props.firestore;
    const nameDocRef = firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('name')
      .doc(newName);
    const nameRef = await nameDocRef.get();
    if (nameRef.exists) {
      throw new Error('Name already exists');
    }
    const created = Date.now();
    const {maxEnabled} = this.checkNamesQuota();
    await nameDocRef.set({
      created,
      updated: created,
      enabled: proxyID === undefined ? false : (!maxEnabled),
      proxy_ref: proxyID || null,
    });
    const url = `/api/v1/users/${this.props.user.uid}/names/${newName}`;
    await axios.post(url);
    const uNameRef = await nameDocRef.get();
    if (!uNameRef.exists || !uNameRef.data().activated) {
      throw new Error('Unexpected error');
    }
    return await this.load();
  }

  async performUpdateName(name, props) {
    const firestore = this.props.firestore;
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('name')
      .doc(name)
      .update(Object.assign({
        updated: Date.now(),
      }, props));
    const url = `/api/v1/users/${this.props.user.uid}/names/${name}`;
    await axios.post(url);
    return await this.load();
  }

  async performUpdateProxy(proxyID, props) {
    const firestore = this.props.firestore;
    await firestore
      .collection('user')
      .doc(this.props.user.uid)
      .collection('proxy')
      .doc(proxyID)
      .update(Object.assign({
        updated: Date.now(),
      }, props));
    const url = `/api/v1/users/${this.props.user.uid}/proxies/${proxyID}`;
    await axios.post(url);
    return await this.load();
  }

  async performValidateName(name) {
    if (!checkName(name)) {
      const {maxEnabled, maxCandidates} = this.checkNamesQuota();
      return {
        valid: false,
        nameInvalid: true,
        maxEnabled,
        maxCandidates,
      };
    }
    const firestore = this.props.firestore;
    const nameRef = await firestore
      .collection('name')
      .doc(name)
      .get();
    if (!nameRef.exists) {
      const {maxEnabled, maxCandidates} = this.checkNamesQuota();
      return {
        valid: true,
        maxEnabled,
        maxCandidates,
      };
    }
    const candidateNames = [...Array(NUM_NAME_CANDIDATES).keys()]
      .map((_) => `${name}-${Math.floor(Math.random() * 1000)}`);
    const candidateRefs = await Promise.all(candidateNames
      .map((cname) => firestore.collection('name').doc(cname).get()));
    const candidates = candidateNames
      .map((cname, cnameIndex) => ({
        name: cname,
        ref: candidateRefs[cnameIndex],
      }))
      .filter((cand) => !cand.ref.exists)
      .map((cand) => cand.name);
    const {maxEnabled, maxCandidates} = this.checkNamesQuota();
    return {
      valid: false,
      candidates,
      maxEnabled,
      maxCandidates,
    };
  }

  checkNamesQuota() {
    const {names, quota} = this.state;
    if (!quota || !quota.data) {
      return {
        maxEnabled: false,
        maxCandidates: false,
      };
    }
    const reserveds = (names || [])
      .filter((name) => name.data && name.data.activated);
    const enableds = reserveds
      .filter((name) => name.data && name.data.enabled);
    return {
      maxEnabled: !(enableds.length < quota.data.enabledNames),
      maxCandidates: !(reserveds.length < quota.data.reservedNames),
    };
  }

  async performRegisterName(name, proxy) {
    return await this.performAddName(name, proxy.id);
  }

  async performAttachName(name, proxy) {
    return await this.performUpdateName(name, {
      proxy_ref: proxy.id,
    });
  }

  /*
  collapseSources() {
    this.setState({
      expand: false,
    })
  }

  expandSources() {
    this.setState({
      expand: true,
    })
  }
  */

  selectTab(event, newValue) {
    const basepath = event ? '/' : window.location.pathname;
    window.history.pushState({}, '', `${basepath}${HASH_TABS[newValue]}`);
    this.setState({
      selectTab: newValue,
    });
  }

  changeName(name, props) {
    this.performUpdateName(name, props)
        .then((data) => {
          this.setState(data);
        })
        .catch((error) => {
          this.showError('errorUpdateName', error);
        })
  }

  deleteProxyRef(name, callback) {
    this.performUpdateName(name.id, {
      proxy_ref: null,
    })
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorUpdateName', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  openName(name) {
    window.location.href = `/n/${name.id}`;
  }

  validateName(name, callback) {
    this.performValidateName(name)
      .then((result) => {
        if (!callback) {
          return;
        }
        callback(result);
      })
      .catch((error) => {
        this.showError('errorValidateName', error);
        if (!callback) {
          return;
        }
        callback({
          error,
        });
      })
  }

  registerNameFor(name, proxy, callback) {
    this.performRegisterName(name, proxy)
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorRegisterName', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  attachNameFor(name, proxy, callback) {
    this.performAttachName(name, proxy)
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorAttachName', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  addName(name, callback) {
    this.performAddName(name)
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorAddName', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  deleteName(name, callback) {
    this.performUpdateName(name, {
      deleted: true,
    })
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorDeleteName', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  deleteProxy(proxyID, callback) {
    this.performUpdateProxy(proxyID, {
      deleted: true,
    })
        .then((data) => {
          this.setState(data, callback);
        })
        .catch((error) => {
          this.showError('errorDeleteProxy', error);
          if (!callback) {
            return;
          }
          callback();
        })
  }

  renderProxies() {
    const { classes, intl } = this.props
    const {maxEnabled, maxCandidates} = this.checkNamesQuota();
    return <Container>
      {(this.state.proxies || [])
          .filter((proxy) => proxy.data && proxy.data.activated).length > 0 &&
        <Container className={classes.proxyTutorial}>
          <Button onClick={() => this.setState({ proxyTutorial: !this.state.proxyTutorial })}>
            <InfoIcon />
            <FormattedMessage id={this.state.proxyTutorial ? 'hideProxyTutorial' : 'showProxyTutorial'} />
          </Button>
        </Container>}
      {this.state.proxyTutorial && <Tutorial />}
      {(this.state.proxies || [])
          .filter((proxy) => proxy.data && proxy.data.activated)
          .map((proxy) => <Proxy
            proxy={proxy}
            selected={proxy.id === this.getActivatingProxyID()}
            names={this.state.names || []}
            quota={this.state.quota}
            maxEnabled={maxEnabled}
            maxCandidates={maxCandidates}
            onDelete={(callback) => this.deleteProxy(proxy.id, callback)}
            onNameInput={(name, callback) => this.validateName(name, callback)}
            onNameClick={(name) => this.openName(name)}
            onNameDelete={(name, callback) => this.deleteProxyRef(name, callback)}
            onNameRegister={(name, callback) => this.registerNameFor(name, proxy, callback)}
            onNameAttach={(name, callback) => this.attachNameFor(name, proxy, callback)}
          />)}
      {(this.state.proxies || [])
          .filter((proxy) => proxy.data && proxy.data.activated).length === 0 &&
        <Tutorial />}
    </Container>
  }

  renderNames() {
    const {classes} = this.props;
    const {quota} = this.state;
    const {maxEnabled, maxCandidates} = this.checkNamesQuota();
    return <Container>
      <Button
        fullWidth
        variant='contained'
        color='primary'
        className={classes.addButton}
        onClick={() => {
          if (maxCandidates) {
            this.setState({ reservedNamesExceeded: true });
          } else {
            this.setState({ newName: true });
          }
        }}
      >
        {maxCandidates ?
          <>
            <ShoppingCartIcon />
            <FormattedMessage
              id='rearchCurrentPlan'
              values={{count: quota && quota.data && quota.data.reservedNames}}
            />
          </> :
          <>
            <AddIcon />
            <FormattedMessage id='addNewName' />
          </>}
      </Button>
      {(this.state.names || [])
          .filter((name) => name.data && name.data.activated)
          .map((name) => <Name
            name={name}
            selected={name.id === this.getNameID()}
            proxies={this.state.proxies || []}
            quota={this.state.quota}
            maxEnabled={maxEnabled}
            maxCandidates={maxCandidates}
            onDelete={(callback) => this.deleteName(name.id, callback)}
            onChange={(props) => this.changeName(name.id, props)}
          />)}
      <NewNameDialog
        open={this.state.newName}
        quota={quota}
        maxEnabled={maxEnabled}
        maxCandidates={maxCandidates}
        onClose={() => this.setState({ newName: false })}
        onNameInput={(name, callback) => this.validateName(name, callback)}
        onNameCreate={(name, callback) => this.addName(name, callback)}
      />
      <ReservedNamesExceededDialog
        open={this.state.reservedNamesExceeded}
        onClose={() => this.setState({ reservedNamesExceeded: false })}
      />
    </Container>;
  }

  renderMain() {
    const {classes} = this.props;
    const selectedTabCands = HASH_TABS
        .map((tab, index) => [tab, index])
        .filter((tabWithIndex) => tabWithIndex[0] === window.location.hash);
    const selectedTab = selectedTabCands.length === 0 ? 0 : selectedTabCands[0][1];
    return (<Container>
      <Paper className={classes.root}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => this.selectTab(event, newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            icon={<ComputerIcon />}
            label={<FormattedMessage id='headerProxies' />}
          />
          <Tab
            icon={<DnsIcon />}
            label={<FormattedMessage id='headerNames' />}
          />
        </Tabs>
      </Paper>

      {selectedTab === 0 && this.renderProxies()}
      {selectedTab === 1 && this.renderNames()}
    </Container>);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            {!this.state.quota && <LoadingPanel />}
            {this.state.quota && this.renderMain()}
          </Route>
          <Route exact path="/settings">
            {!this.state.quota && <LoadingPanel />}
            {this.state.quota && <UserSettingsPage
                quota={this.state.quota}
              />}
          </Route>
          <Route exact path="/p/:proxyId">
            {!this.state.quota && <LoadingPanel />}
            {this.state.quota && this.renderMain()}
          </Route>
          <Route exact path="/n/:nameId">
            {!this.state.quota && <LoadingPanel />}
            {this.state.quota && this.renderMain()}
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default withStyles(styles)(injectIntl(UserPage))
