import { Component } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import DnsIcon from '@material-ui/icons/Dns';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReservedNamesExceededDialog from '../components/ReservedNamesExceededDialog';

const styles = (theme) => ({
  panel: {
    margin: '1em',
    padding: '1em',
  },
  selectedPanel: {
    border: '2px solid orange',
  },
  namePanel: {
    display: 'flex',
  },
  name: {
    marginLeft: '1em',
    fontSize: '1.5em',
  },
  nameId: {
    fontWeight: 'bold',
  },
  enablePanel: {
    textAlign: 'right',
  },
  targetProxyPanel: {
    display: 'flex',
  },
  targetProxy: {
    marginRight: '1em',
  },
  trashButton: {
    textAlign: 'right',
  },
  updatedPanel: {
    display: 'inline',
  },
});

export function getParentFQDN(name) {
  return '.dynna.me';
}

export function getFQDN(name) {
  return `${name.id}${getParentFQDN(name)}`;
}

class Name extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  changeEnable() {
    const {name, onChange} = this.props;
    const enabled = name.data && name.data.enabled;
    if (!onChange) {
      return;
    }
    onChange({
      enabled: !enabled,
    });
  }

  changeProxy(value) {
    const {onChange} = this.props;
    const proxyRef = value === '' ? null : value;
    if (!onChange) {
      return;
    }
    onChange({
      proxy_ref: proxyRef,
    });
  }

  open(name) {
    window.open(`https://${name.id}${getParentFQDN(name)}`)
  }

  render() {
    const {classes, name, selected, maxEnabled} = this.props
    return (
      <Box>
        <Paper className={clsx(
            classes.panel, selected && classes.selectedPanel,
          )} elevation={0}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} className={classes.namePanel}>
              <DnsIcon />
              <Typography className={classes.name}>
                <span className={classes.nameId}>{name.id}</span>
                {getParentFQDN(name)}
              </Typography>
              <Button onClick={() => this.open(name)}>
                <OpenInNewIcon />
              </Button>
            </Grid>
            <Grid item sm={12} md={6} className={classes.enablePanel}>
              {name.data && name.data.updated && <Container className={classes.updatedPanel}>
                <FormattedMessage id='updated' />
                {moment(new Date(name.data.updated)).fromNow()}
              </Container>}
              {name.data && ((!name.data.enabled) && (!name.data.proxy_ref)) &&
                <FormattedMessage id='noProxyRefs' />}
              {name.data && (name.data.enabled || name.data.proxy_ref) &&
                (name.data.enabled || (!name.data.enabled && !maxEnabled) ?
                  <Button
                    variant="outlined"
                    color={(name.data && name.data.enabled) ? "secondary" : "primary"}
                    onClick={() => this.changeEnable()}
                  >
                    <FormattedMessage id={(name.data && name.data.enabled) ? "nameDisable" : "nameEnable"} />
                  </Button> :
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.setState({ enabledNamesExceeded: true })}
                  >
                    <ShoppingCartIcon />
                    <FormattedMessage
                      id="rearchCurrentPlanEnabled"
                      values={{ count: ((this.props.quota || {}).data || {}).enabledNames }}
                    />
                  </Button>
                )}
            </Grid>
            <Grid item sm={12} md={11} className={classes.targetProxyPanel}>
              <Typography className={classes.targetProxy}>
                <FormattedMessage id='targetProxy' />
              </Typography>
              <Select
                value={name.data.proxy_ref || ''}
                onChange={(event) => this.changeProxy(event.target.value)}
              >
                <MenuItem value=''>
                  <em><FormattedMessage id='proxyNotSelected'/></em>
                </MenuItem>
                {(this.props.proxies || []).map((proxy) =>
                  <MenuItem key={proxy.id} value={proxy.id}>{proxy.data.name || proxy.data.proxy_ip}</MenuItem>)}
              </Select>
            </Grid>
            <Grid item sm={12} md={1} className={classes.trashButton}>
              <Button onClick={() => this.setState({ deleteConfirm: true })}>
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <DeleteConfirmDialog
          open={this.state.deleteConfirm}
          onClose={() => this.setState({ deleteConfirm: false })}
          onDelete={this.props.onDelete}
        />
        <ReservedNamesExceededDialog
          open={this.state.enabledNamesExceeded}
          onClose={() => this.setState({ enabledNamesExceeded: false })}
        />
      </Box>
    )
  }
}

export default withStyles(styles)(Name)
