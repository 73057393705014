import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
})

class ReservedNamesExceededDialog extends Component {
  render() {
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle><FormattedMessage id='reservedNamesExceededDialog' /></DialogTitle>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormattedMessage id='reservedNamesExceededMessage' />
            </Grid>
          </Grid>
        </Container>
        <DialogActions>
          <Button
            onClick={this.props.onClose}
          >
            <FormattedMessage id='close' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ReservedNamesExceededDialog)
