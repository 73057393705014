import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { FormattedMessage } from 'react-intl'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  header: {
    textAlign: 'right',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  quota: {
    fontWeight: 'bold',
  },
});

class UserSettingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {classes, quota} = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item md={4}>
          <Typography className={classes.header}>
            <FormattedMessage id='userQuotaHeader' />
          </Typography>
        </Grid>
        <Grid item md={8}>
          <Container>
            <FormattedMessage id='enabledNamesHeader' />
            <span className={classes.quota}>
              {quota.data.enabledNames}
            </span>
          </Container>
          <Container>
            <FormattedMessage id='reservedNamesHeader' />
            <span className={classes.quota}>
              {quota.data.reservedNames}
            </span>
          </Container>
        </Grid>
        <Grid item md={4}>
          <Typography className={classes.header}>
            <FormattedMessage id='withdrawalHeader' />
          </Typography>
        </Grid>
        <Grid item md={8}>
          Not Implemented...
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(UserSettingsPage)
