import { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import firebase from 'firebase/app'
import { withStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import GoogleButton from 'react-google-button'

const styles = {
  root: {
    //height: '60vh',
  },
  header: {
    padding: '2em',
    backgroundColor: '#ddd',
  },
  largeIcon: {
    width: '20vw',
    height: '20vw',
  },
  titlePanel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2em',
  },
  title: {
    width: '60%',
    margin: '1em',
  },
  login: {
    fontSize: '1.2em',
    padding: '2em',
  },
  description: {
    margin: '1em',
    textAlign: 'center',
  },
  mainDescription: {
    margin: '2em',
  },
  mainFigure: {
    width: '90%',
  },
}

class AnonymousPage extends Component {
  signIn() {
    const provider = new firebase.auth.GoogleAuthProvider()
    if (!this.props.onSignIn) {
      return
    }
    this.props.onSignIn(provider)
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container component='main' className={classes.root}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
        >
          <Container className={classes.mainDescription}>
            <Typography component="h1" variant="h2" color="inherit">
              <FormattedMessage id='mainDescription1' />
            </Typography>
            <Typography component="h1" variant="h2" color="inherit">
              <FormattedMessage id='mainDescription2' />
            </Typography>
            <img src='figure.gif' alt='Figure' className={classes.mainFigure} />
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
            <img className={classes.title} src='/logo.png' alt='dynna.me' />
            <Container className={classes.description}>
              <p>
                <FormattedMessage id='loginDescription' />
              </p>
            </Container>
            <GoogleButton
              onClick={() => this.signIn()}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(AnonymousPage)
