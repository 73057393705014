import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { getFQDN } from './Name';
import NewName from './NewName';

const styles = (theme) => ({
  targetIP: {
    marginRight: '1em',
    fontWeight: 'bold',
  }
})

class AttachNameDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      mode: 'create',
    };
  }

  setName() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    const callback = this.state.mode === 'create' ? this.props.onNameRegister : this.props.onNameAttach;
    if (!callback) {
      return;
    }
    callback(this.state.name);
  }

  render() {
    const { classes, proxy, names } = this.props;
    const activatedNames = (names || [])
      .filter((name) => name && name.data && name.data.activated);
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle><FormattedMessage id='setName' /></DialogTitle>
        <RadioGroup
          aria-label='mode'
          value={this.state.mode}
          onChange={(event) => this.setState({
            mode: event.target.value,
            name: null,
          })}
          name='mode-group'
        >
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.proxyPanel}>
                <FormattedMessage id='targetProxy' />
                <span className={classes.targetIP}>
                  {proxy && proxy.data && proxy.data.proxy_ip}
                </span>
                ({proxy && proxy.data && proxy.data.name})
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel value='create' control={<Radio />} label={<FormattedMessage id='createNewName' />} />
              </Grid>
            </Grid>
          </Container>
          {this.state.mode === 'create' && <>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <NewName
                    quota={this.props.quota}
                    maxEnabled={this.props.maxEnabled}
                    maxCandidates={this.props.maxCandidates}
                    onNameInput={this.props.onNameInput}
                    onChange={(value) => this.setState({ name: value })}
                  />
                </Grid>
              </Grid>
            </Container>
          </>}
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  value='existing'
                  control={<Radio disabled={activatedNames.length === 0} />}
                  label={<FormattedMessage id='attachExistingName' />}
                />
              </Grid>
            </Grid>
          </Container>
          {this.state.mode === 'existing' && <>
            <Container>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.namePanel}>
                  <FormattedMessage id='useName' />
                  <Select
                    value={this.state.name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                  >
                    <MenuItem value=''>
                      <em><FormattedMessage id='nameNotSelected'/></em>
                    </MenuItem>
                    {(activatedNames || [])
                      .filter((name) => this.state.includeAlreadyAttached || !(name.data && name.data.proxy_ref))
                      .map((name) =>
                        <MenuItem key={name.id} value={name.id}>{getFQDN(name)}</MenuItem>)}
                  </Select>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox
                        checked={this.state.includeAlreadyAttached}
                        onChange={(event) => this.setState({
                          includeAlreadyAttached: event.target.checked,
                        })}
                      />} label={<FormattedMessage id='includeAlreadyAttached' />} />
                  </FormGroup>
                </Grid>
              </Grid>
            </Container>
          </>}
        </RadioGroup>
        <DialogActions>
          <Button
            disabled={!this.state.name}
            onClick={() => this.setName()}
          >
            <FormattedMessage id='submitName' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(AttachNameDialog)
