import { Component } from 'react';
import clsx from 'clsx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ComputerIcon from '@material-ui/icons/Computer';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ClipLoader from 'react-spinners/ClipLoader';
import { getFQDN } from './Name';
import AttachNameDialog from './AttachNameDialog';
import DeleteConfirmDialog from './DeleteConfirmDialog';

const styles = (theme) => ({
  panel: {
    margin: '1em',
    padding: '1em',
  },
  selectedPanel: {
    border: '2px solid orange',
  },
  namePanel: {
    display: 'flex',
  },
  name: {
    marginLeft: '1em',
    fontSize: '1.5em',
  },
  fqdnPanel: {
    display: 'flex',
    alignItems: 'center',
  },
  ipPanel: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginLeft: '1em',
  },
  fqdn: {
    margin: '0.5em',
  },
  ipValue: {
    fontWeight: 'bold',
  },
  trashButton: {
    textAlign: 'right',
  },
  updatedPanel: {
    textAlign: 'right',
  }
});

class Proxy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newName: false,
      newNameOpened: false,
    };
  }

  componentDidMount() {
    if (this.state.newNameOpened || !this.needToAutoNewName()) {
      return;
    }
    setTimeout(() => this.setState({
      newNameOpened: true,
      newName: true,
    }), 100);
  }

  componentDidUpdate(prevProps) {
    if (this.state.newNameOpened || !this.needToAutoNewName()) {
      return;
    }
    setTimeout(() => this.setState({
      newNameOpened: true,
      newName: true,
    }), 100);
  }

  needToAutoNewName() {
    const {proxy, names, selected} = this.props;
    if (!selected) {
      return false;
    }
    const relatedNames = (names || [])
      .filter((name) =>
        (name && name.data && name.data.proxy_ref) === (proxy && proxy.id))
    return relatedNames.length === 0;
  }

  nameClick(name) {
    const {onNameClick} = this.props;
    if (!onNameClick) {
      return;
    }
    onNameClick(name);
  }

  nameDelete(name) {
    const {onNameDelete} = this.props;
    if (!onNameDelete) {
      return;
    }
    this.setState({
      deletingName: name.id,
    })
    onNameDelete(name, () => this.setState({
      deletingName: null,
    }));
  }

  nameRegister(name) {
    const {onNameRegister} = this.props;
    if (!onNameRegister) {
      return;
    }
    this.setState({
      attachingName: name,
    })
    onNameRegister(name, () => this.setState({
      attachingName: null,
    }));
  }

  nameAttach(name) {
    const {onNameAttach} = this.props;
    if (!onNameAttach) {
      return;
    }
    this.setState({
      attachingName: name.id,
    })
    onNameAttach(name, () => this.setState({
      attachingName: null,
    }));
  }

  render() {
    const { classes, proxy, names, selected, intl } = this.props;
    return (
      <Box>
        <Paper className={clsx(classes.panel, selected && classes.selectedPanel)} elevation={0}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={10} className={classes.namePanel}>
              <ComputerIcon />
              <Typography className={classes.name}>
                {proxy.data.name}
              </Typography>
            </Grid>
            <Grid item sm={12} md={2} className={classes.updatedPanel}>
              {proxy.data && proxy.data.updated && <>
                <FormattedMessage id='updated' />
                {moment(new Date(proxy.data.updated)).fromNow()}
              </>}
            </Grid>
            <Grid item sm={12} md={12} className={classes.fqdnPanel}>
              <Typography className={classes.label}>
                <FormattedMessage id='relatedFQDN' />
              </Typography>
              {(names || []).map((name) =>
                name.data.proxy_ref === proxy.id ? <Chip
                  className={classes.fqdn}
                  variant={this.state.deletingName === name.id ? 'outlined' : 'default'}
                  color={name.data.enabled ? 'primary' : 'default'}
                  label={getFQDN(name)}
                  onClick={() => this.nameClick(name)}
                  onDelete={() => this.nameDelete(name)}
                /> : null)}
              {!this.state.attachingName && <Chip
                  className={classes.fqdn}
                  icon={<AddIcon />}
                  label={intl.formatMessage({ id: 'addName' })}
                  onClick={() => this.setState({ newName: true })}
                />}
              {this.state.attachingName && <ClipLoader />}
            </Grid>
            <Grid item sm={12} md={11} className={classes.ipPanel}>
              <Typography className={classes.label}>
                <FormattedMessage id='relatedIP' />
              </Typography>
              <Typography className={classes.ipValue}>
                {proxy.data.proxy_ip}
              </Typography>
            </Grid>
            <Grid item sm={12} md={1} className={classes.trashButton}>
              <Button onClick={() => this.setState({ deleteConfirm: true })}>
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <AttachNameDialog
          open={this.state.newName}
          proxy={this.props.proxy}
          names={this.props.names}
          quota={this.props.quota}
          maxEnabled={this.props.maxEnabled}
          maxCandidates={this.props.maxCandidates}
          onNameInput={this.props.onNameInput}
          onNameRegister={(name) => this.nameRegister(name)}
          onNameAttach={(name) => this.nameAttach(name)}
          onClose={() => this.setState({ newName: false })}
        />
        <DeleteConfirmDialog
          open={this.state.deleteConfirm}
          onClose={() => this.setState({ deleteConfirm: false })}
          onDelete={this.props.onDelete}
        />
      </Box>
    );
  }
}

export default withStyles(styles)(injectIntl(Proxy))
