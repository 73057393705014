import { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'react-markdown';

const styles = (theme) => ({
  root: {
  },
  step: {
    fontSize: '2em',
    marginTop: '1em',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '1.2em',
  },
  stepDescription: {
    marginLeft: '1em',
    fontSize: '1.2em',
  },
})

class Tutorial extends Component {
  render() {
    const { classes, intl } = this.props
    return (
      <Container maxWidth={this.props.maxWidth || 'md'} className={classes.root}>
        <p className={classes.description}>
          <FormattedMessage id='dynnameIntroduction' />
        </p>
        <Typography component="h1" variant="h2" color="inherit" className={classes.step}>
          <FormattedMessage id='step1PrepareProxy' />
        </Typography>
        <p className={classes.stepDescription}>
          <ReactMarkdown
            children={intl.formatMessage({
              id: 'step1PrepareProxyDescription',
            })}
            linkTarget='_blank'
          />
        </p>
        <Typography component="h1" variant="h2" color="inherit" className={classes.step}>
          <FormattedMessage id='step2ConfigureProxy' />
        </Typography>
        <p className={classes.stepDescription}>
          <ReactMarkdown
            children={intl.formatMessage({
              id: 'step2ConfigureProxyDescription',
            })}
            linkTarget='_blank'
          />
        </p>
      </Container>
    )
  }
}

export default withStyles(styles)(injectIntl(Tutorial))
