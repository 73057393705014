import { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import ClipLoader from "react-spinners/ClipLoader"

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(12),
  },
})

class LoadingPanel extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <ClipLoader />
        <div>Loading...</div>
      </div>
    )
  }
}

export default withStyles(styles)(LoadingPanel)
