export const messages = {
  cancel: 'Cancel',
  close: 'Close',
  currentUser: 'User Settings',
  userManual: 'User Manual',
  signout: 'Logout',
  loginDescription: 'You need to be logged in to use dynna.me.',
  mainDescription1: 'Easy-to-Use',
  mainDescription2: 'Domain Name + HTTPS',
  headerProxies: 'Proxies',
  headerNames: 'Names',
  updated: 'Updated:',
  targetProxy: 'Target Proxy:',
  nameEnable: 'Enable',
  nameDisable: 'Disable',
  relatedFQDN: 'Domain Name(s):',
  relatedIP: 'IP:',
  addName: 'Add',
  setName: 'Domain Setting',
  addNewName: 'New Domain Registration',
  addNameDialog: 'New Domain',
  createNewName: 'Create New Domain Name',
  attachExistingName: 'Attach Existing Domain Name',
  useName: 'Domain Name:',
  nameNotSelected: 'Not selected',
  includeAlreadyAttached: 'Include already attached names',
  submitName: 'Register',
  alreadyExistsName: 'The name is already registered. Other candidates:',
  deleteConfirmDialog: 'Delete confirmation',
  deleteConfirmMessage: 'Once this resource is deleted, it cannot be reverted. Do you wish to proceed?',
  deleteConfirm: 'Delete',
  newDisabledName: 'The number of enabled domains has been exceeded ({count}).The domain will be created in a disabled state.',
  rearchCurrentPlan: 'The number of reserved domains has been exceeded ({count}).',
  rearchCurrentPlanEnabled: 'Cannot enable: The number of enabled domains has been exceeded({count})',
  nameInvalid: 'Domain name must be [a-z][a-z0-9-]{3,31}.',
  reservedNamesExceededDialog: 'Increase the number of domains',
  reservedNamesExceededMessage: 'This is currently in beta. We will provide plans that can hold even more domains. Please wait until the service starts.',
  noProxyRefs: 'Target Proxy not set',
  showProxyTutorial: 'Display instructions on proxy settings',
  hideProxyTutorial: 'Hide instructions on proxy settings',
  dynnameIntroduction: 'Welcome to dynna.me! In order to use dynna.me, please follow the instructions below to set up the service.',
  step1PrepareProxy: 'Step 1: Prepare the proxy',
  step1PrepareProxyDescription: `
In order to use dynna.me, you need to set dynname-proxy on the front of the service you want to give a name to.

See [dynname-proxy](https://github.com/yacchin1205/dynna.me)
`,
  step2ConfigureProxy: 'Step 2: Configure the proxy',
  step2ConfigureProxyDescription: 'When you start dynname-proxy, you will see a URL like the one below. Open the URL that contains the IP address you want to name.',
  userQuotaHeader: 'User Quota',
  withdrawalHeader: 'Delete user',
  enabledNamesHeader: 'Number of domains that can be enabled: ',
  reservedNamesHeader: 'Number of domains that can be reserved: ',
};
