import { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { FormattedMessage } from 'react-intl';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import NewName from './NewName';

const styles = (theme) => ({
})

class NewNameDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
    };
  }

  setName() {
    if (this.props.onClose) {
      this.props.onClose();
    }
    const {onNameCreate} = this.props;
    if (!onNameCreate) {
      return;
    }
    onNameCreate(this.state.name);
  }

  render() {
    return (
      <Dialog onClose={this.props.onClose} open={this.props.open}>
        <DialogTitle><FormattedMessage id='addNameDialog' /></DialogTitle>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NewName
                quota={this.props.quota}
                maxEnabled={this.props.maxEnabled}
                maxCandidates={this.props.maxCandidates}
                onNameInput={this.props.onNameInput}
                onChange={(value) => this.setState({ name: value })}
              />
            </Grid>
          </Grid>
        </Container>
        <DialogActions>
          <Button
            disabled={!this.state.name}
            onClick={() => this.setName()}
          >
            <FormattedMessage id='submitName' />
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(NewNameDialog)
