import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { withStyles } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import MenuIcon from '@material-ui/icons/Menu';
import { ToastContainer, toast } from 'react-toastify'

import firebase from 'firebase/app'
import { firebaseApp } from './firebase/'
import Footer from './components/Footer.js'
import LoadingPanel from './components/LoadingPanel.js'
import AnonymousPage from './pages/AnonymousPage.js'
import UserPage from './pages/UserPage.js'

const URL_USER_MANUAL = 'https://scrapbox.io/dynname/';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  avatar: {
    paddingLeft: '0.5em',
  },
  titlePanel: {
    flexGrow: 1,
  },
  title: {
    height: '32px',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(4),
  },
})

class Base extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: undefined,
      loginMenuOpen: false,
    }
    this.loginRef = React.createRef()
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({
        user,
        avatar: this.getAvatarFrom(user),
      })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    this.emitErrorToast(prevState)
  }

  getAvatarFrom(user) {
    const avatarURL = user.photoURL || null;
    if (!avatarURL) {
      return null;
    }
    return <Avatar alt={user.displayName} src={avatarURL} />
  }

  emitErrorToast(prevState) {
    if (prevState.error === this.state.error || this.state.error === null) {
      return
    }
    const msg = this.state.error.message ? <FormattedMessage id={this.state.error.message} /> : `Error: ${this.state.error}`
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => this.setState({
        error: null
      })
    })
  }

  signIn(provider) {
    firebase.auth().signInWithRedirect(provider)
  }

  signOut() {
    firebase.auth().signOut()
      .then(() => {
        this.setState({
          user: null,
        })
      }).catch(function(error) {
        this.showError('errorSignOut', error)
      })
  }

  openDashboard() {
    window.location.href = '/';
  }

  openUserSettings() {
    window.location.href = '/settings';
  }

  openUserManual() {
    window.open(URL_USER_MANUAL, '_blank');
  }

  showError(message, error) {
    console.error(message)
    console.error(error)
    this.setState({
      error: {
        message,
        detail: error,
      },
    })
  }

  toggleLoginMenu() {
    this.setState({
      loginMenuOpen: !this.state.loginMenuOpen,
    })
  }

  listKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({
        loginMenuOpen: false,
      })
    }
  }

  closeLoginMenu() {
    this.setState({
      loginMenuOpen: false,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <CssBaseline />
        {this.state.user &&
          <AppBar position="absolute" className={clsx(classes.appBar)}>
            <Toolbar className={classes.toolbar}>
              <Container
                className={classes.titlePanel}
                onClick={() => this.openDashboard()}
              >
                <img className={classes.title} src='/logo.png' alt='dynna.me' />
              </Container>
              {this.state.user && <>
                  <Typography
                    ref={this.loginRef}
                    aria-controls={this.state.loginMenuOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    color="inherit"
                    noWrap
                    onClick={() => this.toggleLoginMenu()}
                  >
                    <MenuIcon />
                  </Typography>
                  <Popper open={this.state.loginMenuOpen} anchorEl={this.loginRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={() => this.closeLoginMenu()}>
                            <MenuList
                              autoFocusItem={this.state.loginMenuOpen}
                              id="menu-list-grow"
                              onKeyDown={(ev) => this.listKeyDown(ev)}
                            >
                              <MenuItem onClick={() => this.openUserSettings()}>
                                <FormattedMessage id='currentUser'/>
                                : {this.state.user.email}
                                {this.state.avatar && <span className={classes.avatar}>
                                    {this.state.avatar}
                                  </span>}
                              </MenuItem>
                              <MenuItem onClick={() => this.openUserManual()}>
                                <FormattedMessage id='userManual'/>
                              </MenuItem>
                              <MenuItem onClick={() => this.signOut()}>
                                <FormattedMessage id='signout'/>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>}
            </Toolbar>
          </AppBar>}
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            {this.state.user === undefined &&
              <LoadingPanel />}
            {this.state.user === null &&
              <AnonymousPage
                onSignIn={(provider) => this.signIn(provider)}
              />}
            {this.state.user &&
              <UserPage
                user={this.state.user}
                firestore={this.props.firestore || firebaseApp.firestore()}
                onError={(message, error) => this.showError(message, error)}
              />}
          </Container>
          <Footer />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        </main>
      </div>
    )
  }
}

export default withStyles(styles)(Base)
