import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ClipLoader from 'react-spinners/ClipLoader';
import { getParentFQDN } from './Name';
import ReservedNamesExceededDialog from '../components/ReservedNamesExceededDialog';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  parentFQDN: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  maxCandidates: {
    margin: '0.5em',
  },
  maxEnabled: {
    margin: '0.5em',
    color: '#555',
  },
  nameCandidate: {
    marginLeft: '0.5em',
  },
  nameCandidates: {
    margin: '0.5em',
  },
  nameInvalid: {
    margin: '0.5em',
  },
});

class NewName extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
    };
  }

  changeName(name) {
    this.setState({ name, loading: true }, () => {
      if (!this.props.onNameInput) {
        this.setState({
          loading: false,
        });
        return;
      }
      this.props.onNameInput(name, ({ error, valid, nameInvalid, candidates, maxEnabled, maxCandidates }) => {
        if (error) {
          this.setState({
            loading: false,
          });
          return;
        }
        if (this.props.onChange) {
          this.props.onChange(valid ? this.state.name : null);
        }
        this.setState({
          valid,
          nameInvalid,
          candidates,
          maxEnabled,
          maxCandidates,
          loading: false,
        });
      });
    });
  }

  render() {
    const {classes, quota} = this.props
    return (
      <Container>
        <Container className={classes.root}>
          <TextField
            disabled={this.props.maxCandidates}
            value={this.state.name}
            onChange={ev => this.changeName(ev.target.value)} />
          <span className={classes.parentFQDN}>
            {getParentFQDN(null)}
          </span>
          {this.state.loading && <ClipLoader />}
        </Container>
        {this.state.valid === false && !this.state.nameInvalid &&
          <Container className={classes.nameCandidates}>
            <FormattedMessage id='alreadyExistsName' />
            {(this.state.candidates || []).map((candidate) =>
              <Chip
                className={classes.nameCandidate}
                label={candidate}
                onClick={() => this.changeName(candidate)}
              />)}
          </Container>}
        {this.state.nameInvalid &&
          <Container className={classes.nameInvalid}>
            <FormattedMessage id='nameInvalid' />
          </Container>}
        {this.props.maxCandidates &&
          <Container className={classes.maxCandidates}>
            <Button
              fullWidth
              color='secondary'
              onClick={() => this.setState({ reservedNamesExceeded: true })}
            >
              <ShoppingCartIcon />
              <FormattedMessage
                id='rearchCurrentPlan'
                values={{count: quota && quota.data && quota.data.reservedNames}}
              />
            </Button>
          </Container>}
        {this.props.maxEnabled && !this.props.maxCandidates &&
          <Container className={classes.maxEnabled}>
            <FormattedMessage
              id='newDisabledName'
              values={{count: quota && quota.data && quota.data.enabledNames}}
            />
          </Container>}
        <ReservedNamesExceededDialog
          open={this.state.reservedNamesExceeded}
          onClose={() => this.setState({ reservedNamesExceeded: false })}
        />
      </Container>
    )
  }
}

export default withStyles(styles)(NewName)
