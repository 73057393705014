export const messages = {
  cancel: 'キャンセル',
  close: '閉じる',
  currentUser: 'ユーザ設定',
  userManual: 'ユーザーマニュアル',
  signout: 'ログアウト',
  loginDescription: 'dynna.meを利用するにはログインする必要があります。',
  mainDescription1: 'かんたん設定',
  mainDescription2: 'ドメイン + HTTPS',
  headerProxies: 'プロキシ一覧',
  headerNames: 'ドメイン名一覧',
  updated: '更新日時:',
  targetProxy: '接続先プロキシ:',
  nameEnable: '有効化',
  nameDisable: '無効化',
  relatedFQDN: 'ドメイン名:',
  relatedIP: 'IP:',
  addName: '追加',
  addNewName: '新規ドメイン登録',
  addNameDialog: '新規ドメイン',
  setName: 'ドメイン名設定',
  createNewName: '新しくドメイン名を登録して設定',
  attachExistingName: '既存のドメイン名を設定',
  useName: 'ドメイン名:',
  nameNotSelected: '未選択',
  includeAlreadyAttached: 'すでに設定されているドメイン名を含める',
  submitName: '登録',
  alreadyExistsName: 'すでに登録されている名前です。別の候補:',
  deleteConfirmDialog: '削除確認',
  deleteConfirmMessage: 'このリソースは削除すると元に戻せません。続行しますか？',
  deleteConfirm: '削除',
  newDisabledName: '有効化可能な最大ドメイン数に達しています({count})。ドメインは無効状態で作成されます。',
  rearchCurrentPlan: '確保可能な最大ドメイン数に達しています({count})。',
  rearchCurrentPlanEnabled: '有効化不可: 有効化可能な最大ドメイン数に達しています({count})',
  nameInvalid: 'ドメイン名は 4文字〜32文字 / 1文字目は英小文字 / 2文字目以降は英小文字数字またはハイフン である必要があります。',
  reservedNamesExceededDialog: 'ドメイン数の引き上げ',
  reservedNamesExceededMessage: '現在ベータバージョンです。さらに多くのドメインを保持できるプランをご用意する予定です。サービス開始までお待ちください。',
  noProxyRefs: '接続先プロキシ未設定',
  showProxyTutorial: 'プロキシ設定に関する説明を表示',
  hideProxyTutorial: 'プロキシ設定に関する説明を隠す',
  dynnameIntroduction: 'dynna.meにようこそ！dynna.meを利用するためには以下の手順にしたがいサービスの設定を行なってください。',
  step1PrepareProxy: '手順1: プロキシを準備する',
  step1PrepareProxyDescription: `
dynna.meを利用するためには、名前をつけたいサービスの前面に[dynname-proxy](https://github.com/yacchin1205/dynna.me)を設定する必要があります。

![Figure](/figure.gif)

dynname-proxyは[Docker Image](https://hub.docker.com/r/yacchin1205/dynna.me)として提供しています。
以下のようにWebサービスの前面に配置することでdynna.meでの名前設定が可能になります。
この例では、Etherpadをdynna.meによりHTTPS対応することができます。

\`\`\`
services:
  sample-app:
    # example: etherpad https://github.com/ether/etherpad-lite
    # The Etherpad service listens on port 9001.
    image: etherpad/etherpad
  dynname-proxy:
    image: yacchin1205/dynna.me:latest
    ports:
      - 443:443
      - 80:80
    environment:
      # Etherpad Container Name(sample-app):Etherpad Port(9001)
      - UPSTREAM_HTTP_HOST=sample-app
      - UPSTREAM_HTTP_PORT=9001
      - UPSTREAM_HTTPS_HOST=sample-app
      - UPSTREAM_HTTPS_PORT=9001
    hostname: my-etherpad-service
    links:
      - sample-app
    volumes:
      - "dynname:/opt/dynname/data"
      - "etc-letsencrypt:/etc/letsencrypt"
      - "var-lib-letsencrypt:/var/lib/letsencrypt"
volumes:
  etc-letsencrypt: {}
  var-lib-letsencrypt: {}
  dynname: {}
\`\`\`

docker-compose.ymlと同じディレクトリで、以下のようにしてサービス及びdynname-proxyコンテナを起動します。

\`\`\`
$ docker-compose up -d
\`\`\`


`,
  step2ConfigureProxy: '手順2: プロキシを設定する',
  step2ConfigureProxyDescription: `
dynname-proxyを起動すると、コンテナログに以下のようなURLが表示されます。名前をつけたいIPアドレスを含むURLを開きます。

\`\`\`
$ docker-compose logs dynname-proxy
dynname-proxy_1  | ======= dynname-proxy started successfully =======
dynname-proxy_1  |
dynname-proxy_1  | Please open http://(YOUR_DEVICE_IP_ADDRESS)/.dynname/config \\
    in your browser and activate the proxy at dynna.me.
dynname-proxy_1  |
dynname-proxy_1  | ==================================================
\`\`\`

このURLを開くと、https://dynna.me の画面が開きますので、ここでドメイン名を設定します。
ドメイン名が設定されると、最長5分の後、Let's Encryptによるサーバ証明書が発行され、HTTPSアクセスが可能になります。



`,
  userQuotaHeader: '利用可能ドメイン',
  withdrawalHeader: 'ユーザ削除',
  enabledNamesHeader: '有効化可能なドメイン数: ',
  reservedNamesHeader: '確保可能なドメイン数: ',
};
