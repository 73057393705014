import { Component } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { IntlProvider } from 'react-intl'
import * as locales from '@material-ui/core/locale'
import moment from 'moment';
import 'moment/locale/ja';

import { messages as messagesEn } from './translations/en.js'
import { messages as messagesJa } from './translations/ja.js'

const messages = {
  en: messagesEn,
  ja: messagesJa,
}

const theme = {
  palette: {
    primary: {
      light: '#4dabf5',
      main: '#2196f3',
      dark: '#1769aa',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff6333',
      main: '#ff3d00',
      dark: '#b22a00',
      contrastText: '#fff',
    },
  },
};


class Context extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'en',
    }
  }

  componentDidMount() {
    const language = navigator.language.split(/[-_]/)[0];
    const lang = Object.keys(messages).findIndex(item => item === language) < 0 ? 'en' : language;
    moment.locale(lang);
    this.setState({
      locale: lang,
    })
  }

  render() {
    return (
      <IntlProvider
        messages={messages[this.state.locale]}
        locale={this.state.locale}
      >
        <ThemeProvider
          theme={createTheme(
            Object.assign(
              {},
              locales[this.state.locale] || {},
              theme,
            ),
          )}
        >
          {this.props.children}
        </ThemeProvider>
      </IntlProvider>
    )
  }
}

export default Context
